@tailwind base;
@tailwind components;
@tailwind utilities;

/* App.css */
body {
  background-attachment: scroll;
}

html,
body {

  /* Khi body html không cập nhật đúng height có thể dùng CSS sau */
  min-height: 100%;
  height: auto;

  background-color: white;
  font-family: sans-serif;
}

.scroll-container {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}


.app {
  width: 100%;
  min-height: 100vh;
  /* Full height of the viewport */
  display: flex;
  flex-direction: column;
  /* Stacks children vertically */
  overflow: hidden;
  /* scale: 75%; */
}

.app-body {
  display: flex;
  flex-grow: 1;
  /* Takes up all available space */
}

.side-bar {
  flex: 3;
  /* 20% of the space */
  position: fixed;
  top: 0;
  left: 0;
  width: 30%;
  /* Specifies the width */
  height: 100vh;
  /* Full height of the viewport */
  background-color: #f4f4f4;
  /* Example background color */
}

.content-area {
  flex: 7;
  /* 80% of the space */
  margin-left: 30%;
  /* Push content to the right, matching the sidebar's width */
  width: 70%;
  /* Remaining width */
  min-height: 100vh;
  /* Full viewport height */
  background-color: #fff;
  /* Adjust color as needed */
}

.align-item {
  margin: 20px 100px;
  border-radius: 20px;
  box-shadow: 20px 12px 5px 3px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  /* border: 1px solid #000; */
  height: 100%;
  display: flex;
  align-items: center;
}

/* When sidebar is hidden */
.app.hide-sidebar .content-area {
  margin-left: 0%;
  /* padding: 20px; */
  /* Adjust the padding as needed */
}

.app.hide-sidebar .align-item {
  display: block;
  margin: 0 auto;
  width: 100%;
  box-shadow: none;
}

.app.hide-sidebar .align-item>div {
  width: 100%;
}

@media (max-width: 900px) {
  .align-item {
    margin: 0;
    border-radius: 0 !important;
    display: flex;
    justify-content: center;
  }

  .align-item>div {
    height: auto;
    margin: 0;
    /* margin-left: 35px; */
    width: 80%;
  }

  .side-bar {
    flex: 2;
    width: 20%;
    /* border-radius: 20px; */
    box-shadow: 20px 12px 5px 3px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    ;
  }

  .content-area {
    /* flex: 8; */
    width: 80%;
    margin-left: 19%;

  }
}